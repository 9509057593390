<template>
  <div>
    <h3>Settings</h3>
    <div class="card" v-if="! loading" id="settings_form">
      <div class="card-body">
        <table class="table">
          <thead>
            <tr>
              <th>Key</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(line, i) in params" :key="`param-${i}`">
              <td>{{ line.key }}</td>
              <td>
                <input type="text" class="form-control" v-model="line.value" placeholder="Value">
              </td>
            </tr>
          </tbody>
        </table>
        <div class="mt-3 text-end">
          <button class="btn btn-primary btn-sm" @click.prevent="submit"><i class="fa fa-save me-2"></i> save</button>
        </div>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      params: {},
      loading: true
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get('/api/v1/admin/settings').then(response => {
        this.params = response.data.params
        this.loading = false
      })
    },

    submit() {
      let data = {
        form_id: 'settings_form',
        settings: this.params
      }

      this.$axios.post('/api/v1/admin/settings', data).then(() => {
        this.fetch()
      })
    }
  }
}
</script>